import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  // Timeout can be added to handle long-running requests
  timeout: 10000, // 10 seconds
});

// Add a request interceptor to set dynamic headers
axiosInstance.interceptors.request.use(
  (config) => {
    // Example: Add authorization token if available
    const token = localStorage.getItem('token'); // Replace with your token logic
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request Interceptor Error:', error.message);
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle responses and errors
axiosInstance.interceptors.response.use(
  (response) => {
    // Optionally process successful responses here
    return response;
  },
  (error) => {
    if (error.response) {
      // Server returned a response outside the 2xx range
      const { status, data } = error.response;

      console.error('Response Error:', {
        status,
        data,
      });

      // Handle specific HTTP status codes
      switch (status) {
        case 400:
          console.error('Bad Request:', data.message || 'Invalid data sent to the server.');
          break;
        case 401:
          console.error('Unauthorized: Token may have expired.');
          // Redirect to login or refresh token logic
          break;
        case 403:
          console.error('Forbidden: Access denied.');
          break;
        case 404:
          console.error('Not Found: The requested resource does not exist.');
          break;
        case 500:
          console.error('Internal Server Error: Please try again later.');
          break;
        default:
          console.error(`Error ${status}: ${data.message || 'An error occurred.'}`);
      }
    } else if (error.request) {
      // No response received from the server
      console.error('No Response:', error.request);
    } else {
      // Error setting up the request
      console.error('Request Setup Error:', error.message);
    }

    // Handle offline scenarios
    if (!navigator.onLine) {
      console.error('Network Error: You are offline.');
    }

    return Promise.reject(error);
  }
);

// Axios utility functions for HTTP methods
const api = {
  get: (url, config = {}) => axiosInstance.get(url, config),
  post: (url, data = {}, config = {}) => axiosInstance.post(url, data, config),
  put: (url, data = {}, config = {}) => axiosInstance.put(url, data, config),
  delete: (url, config = {}) => axiosInstance.delete(url, config),

  // Function for FormData requests
  formData: (url, formData, config = {}) =>
    axiosInstance.post(url, formData, {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'multipart/form-data', // Override content type for FormData
      },
    }),
};

export default api;
